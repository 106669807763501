<template>
  <div id="app" :class="logged ? 'logged' : 'unlogged'">
    <!--transition name="fade"-->
    <div v-if="state.isLoaded">
      <AppHeader v-if="!state.isOffline && $route.name !== 'trust'"/>
      <!--div class="wallet-container">
          <a class="button mx-auto button-wallet" href="shoebox://">Launch apple wallet</a>
      <a class="button mx-auto button-wallet" href="https://www.android.com/payapp/">Launch google wallet</a>
      </!--div-->

      <div class="app-main" v-if="!state.isOffline">
        <router-view/>
      </div>
      <OfflineView v-if="state.isOffline"></OfflineView>
      <AppFooter v-if="!state.isOffline && $route.name !== 'trust'"/>
      <ErrorBanner v-if="state.displayErrorBanner"/>
    </div>
    <!--/transition-->
    <div v-if="!state.isLoaded">
      <div class="loader">Loading...</div>
    </div>
    <button
      v-show="canAddToHome"
      ref="addToHome"
      id="add-to-home"
      class="button mx-auto"
    >
      Ajouter à l'écran d'accueil
    </button>
  </div>
</template>

<script>
import ErrorBanner from "@/components/error-banner/error-banner.vue";
import AppHeader from "@/components/app-header/app-header.vue";
import AppFooter from "@/components/app-footer/app-footer.vue";
import OfflineView from "@/components/offline/offline.vue";
import store from "@/store";
import router from "@/router";
import {checkUserSession, initPlatform, clearStoreToken} from "@/store/api";
// import { Screeb } from '@/helpers/screeb.js'

export default {
  components: {ErrorBanner, AppHeader, AppFooter, OfflineView},
  data: () => {
    return {
      state: store.state,
      canAddToHome: false,
      //pages with no redirections to login
      redirectToLoginExceptions: [
        "signup",
        "login",
        "trust",
        "contact",
        "mentions",
        "activation"
      ]
    };
  },
  computed: {
    logged() {
      if (this.$store.state.token) {
        return this.$store.state.user
          ? this.$store.state.user.email !== undefined
          : false;
      }
      return false;
    },
  },

  created: async function () {
    this.loaderSpinner();
    //console.log('process BASE URL', process.env.BASE_URL)
    console.log('router', router)
    // Get actual route name
    const currentRouteName = router.currentRoute.value.name;
    console.log(
      "app created with currentRouteName",
      router,
      router.currentRoute.value,
      this.$route
    );
    let fromTrustPage =
      document.URL.includes("trust") || router.currentRoute.value.params.trust;
    console.log("APP VUEis from trust page ? ", fromTrustPage);
    if (fromTrustPage) await clearStoreToken();
    // Fetch request informations before displaying the app (get platofrm config...)
    initPlatform().then(
      () => {
        checkUserSession().then(
          () => {
            store.commit("isOffline", false);
            store.commit("isLoaded", true);

            // console.log('workbox', this.$workbox)
            this.checkPwaInstalled();
            // Debug
            // console.log("[DEBUG] App create :", session, store.state.isLoaded, currentRouteName)

            // Go to home if was on offline view
            if (currentRouteName === "offline") {
              router.replace({ name: "home" }).catch(() => {});
            }
          },
          (err) => {
            console.debug("check user session error", err);
            store.commit("isLoaded", true);


            console.log(
              "current route ",
              router.currentRoute.value,
              this.redirectToLoginExceptions.includes(router.currentRoute.value.name)
            );
            if (
              !this.redirectToLoginExceptions.includes(router.currentRoute.value.name) &&
              router.currentRoute.value.name !== null
            ) {
              console.debug(
                "check session error, go to login with replace history"
              );
              router.replace({ path: "/login" }).catch(() => {});
            }
          }
        );
      },
      (err) => {
        // Display error in console
        console.error("[DEBUG] App create error :", err, currentRouteName);
        store.commit("isOffline", true);
        store.commit("isLoaded", true);
      }
    );
  },


  beforeMount() {
    // Screeb.add()
  },

  methods: {
    loaderSpinner() {
      const loaderStyleTag = document.createElement("style");
      loaderStyleTag.textContent = `
        .fade-enter-active, .fade-leave-active {
          transition: opacity 1s;
        }
        .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
          opacity: 0;
        }
        .loader,
        .loader:after {
            border-radius: 50%;
            width: 10em;
            height: 10em;
        }
        .loader {
            width: 100px;
            height: 100px;
            left: 50%;
            top: 50%;
            margin: -50px 0 0 -50px;
            font-size: 10px;
            position: absolute;
            text-indent: -9999em;
            border-top: 1.1em solid rgba(0,0,0, 0.2);
            border-right: 1.1em solid rgba(0,0,0, 0.2);
            border-bottom: 1.1em solid rgba(0,0,0, 0.2);
            border-left: 1.1em solid #000000;
            -webkit-transform: translateZ(0);
            -ms-transform: translateZ(0);
            transform: translateZ(0);
            -webkit-animation: load8 1.1s infinite linear;
            animation: load8 1.1s infinite linear;
        }
        @-webkit-keyframes load8 {
            0% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }
            100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }
        @keyframes load8 {
            0% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }
            100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }
      `;
      document.body.appendChild(loaderStyleTag);
    },

    checkPwaInstalled() {
      let deferredPrompt;
      const addBtn = this.$refs.addToHome;
      window.addEventListener("beforeinstallprompt", (e) => {
        console.log("before install prompt event", e);
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        deferredPrompt = e;
        // Update UI to notify the user they can add to home screen
        this.canAddToHome = true;

        addBtn.addEventListener("click", (e) => {
          console.log("on button click");
          // hide our user interface that shows our A2HS button
          this.canAddToHome = false;
          // Show the prompt
          deferredPrompt.prompt();
          // Wait for the user to respond to the prompt
          deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === "accepted") {
              console.log("User accepted the A2HS prompt");
            } else {
              console.log("User dismissed the A2HS prompt");
            }
            deferredPrompt = null;
          });
        });
      });
    },
  },
};
</script>
