<template>
  <div class="btn-card-action-wrapper" :class="{ disabled: disabled }">
    <router-link
      to="#"
      custom
    >
      <button
        @click="click"
        :disabled='disabled'
        :class='"btn-card-action column align-middle"+(disabled?" disabled":"")'
      >
        <!-- URL icon -->
        <CardIcon
          :background="infos.background"
          :icon="infos.icon"
          :disabled='disabled'
        />

        <!-- Display notifications -->
        <div class="align-center">
          <span class="btn-card-action-text" v-html="infos.text"></span>
          <div v-if="infos.notif" class="btn-card-action-notif">
            <span v-html="infos.notif"></span>
            <span class="notif-point"></span>
          </div>
        </div>
      </button>
    </router-link>
  </div>
</template>

<script src="./btn-card-action.js"></script>
<style lang="scss" src="./btn-card-action.scss"></style>
