export default {
  name: "CardItem",
  components: {},
  props: {
    card: { type: Object, default: () => {} },
    withDetails: { type: Boolean, defaut: false }
  },
  data () {
    return {}
  },
  created () {},
  computed: {
    cardName () {
      return (
        this.$store.state.user.firstname + " " + this.$store.state.user.lastname
      )
    }
  },
  methods: {}
}
