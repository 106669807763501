<template>
  <div class="card-item card-item-wallester">
    <Spinner v-if="loading"/>
    <div v-if="flipEnabled" class="scene">
      <div class="card" :class="[flipped ? 'is-flipped' : '']">
        <div class="card__face card__face--front">
          <CardContentWallester
            :card="card"
            :type="'front'"
            :displayedCard="displayedCard"
          />
        </div>
        <div class="card__face card__face--back">
          <button class="card__copy  card__num-copy" @click="copyCardNum">
            <i class="sofont sofont-copy"></i>
            {{ $t("card.copy") }}
          </button>
<!--          <div role="alert" v-if="copyAlert" class="alert copy_alert">-->
<!--            {{ copyAlert }}-->
<!--            <button @click="showAlert(false)">-->
<!--              <i class="sofont sofont-cross-close"></i>-->
<!--            </button>-->
<!--          </div>-->

          <CardContentWallester
            :card="card"
            :type="'back'"
            :displayedCard="displayedCard"
          />
<!--          <div v-if="displayedCard.secret" class="card__secret">-->
<!--            &lt;!&ndash;button class="card__copy card__secret-copy" @click="copyCardSecret">-->
<!--           <i class="sofont sofont-copy"></i>-->
<!--           {{ $t("card.copyCode") }}-->
<!--          </!&#45;&#45;button&ndash;&gt;-->
<!--            <div class="card__secret-container">-->
<!--              <span>{{ $t('card.validationCode') }}&nbsp;:&nbsp;</span>-->
<!--              <span class="card__secret-code">{{ displayedCard.secret }}-->
<!--                  <button class="card__copy card__secret-copy" @click="copyCardSecret">-->
<!--            <i class="sofont sofont-copy"></i>-->
<!--            {{ $t("card.copyCode") }}-->
<!--           </button>-->
<!--              </span>-->
<!--              <i class="sofont sofont-help tooltip-icon" @mouseover="showHelp = true" @mouseleave="showHelp = false">-->
<!--                <div class="tooltip" v-if="showHelp" v-html="$t('card.tooltip')"></div>-->
<!--              </i>-->
<!--            </div>-->

<!--          </div>-->
        </div>
      </div>
    </div>
    <div v-else>
      <div class="card">
        <div class="card__face card__face--front">
          <CardContentWallester
            :card="card"
            :type="'front'"
            :action="action"
            :displayedCard="displayedCard"
          />
        </div>
<!--        <div class="card__face card__face&#45;&#45;back">-->
<!--          <button class="card__copy  card__num-copy" @click="copyCardNum">-->
<!--            <i class="sofont sofont-copy"></i>-->
<!--            {{ $t("card.copy") }}-->
<!--          </button>-->
<!--          <div role="alert" v-if="copyAlert" class="alert copy_alert">-->
<!--            {{ copyAlert }}-->
<!--            <button @click="showAlert(false)">-->
<!--              <i class="sofont sofont-cross-close"></i>-->
<!--            </button>-->
<!--          </div>-->

<!--          <div v-if="displayedCard.secret" class="card__secret">-->
<!--            <div class="card__secret-container">-->
<!--              <span>{{ $t('card.validationCode') }}&nbsp;:&nbsp;</span>-->
<!--              <span class="card__secret-code">{{ displayedCard.secret }}-->
<!--                  <button class="card__copy card__secret-copy" @click="copyCardSecret">-->
<!--                    <i class="sofont sofont-copy"></i> {{ $t("card.copyCode") }}-->
<!--                  </button>-->
<!--              </span>-->
<!--            </div>-->

<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script src="./card-item-wallester.js"></script>
<style scoped lang="scss" src="./card-item-wallester.scss"></style>
