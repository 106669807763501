<template>
  <div>
    <!-- Display the list of available cards -->
    <GetterWrapper id="home" :api-load="apiLoad" @data="onData" class="columns cards-list">
      <div class="home row align-center">
        <Popup class="accept-card-pop" v-if="showPopupAcceptationCard" :hasFooter="true">
          <h2>{{ $t('card.popup.accept.title', { value: activeCard.name || ''}) }}</h2>
          <div v-if="activeCard" class="text-left">
            <ViewBlocs :blocs="tos" section="tos"  />
          </div>
          <template v-slot:footer >

              <InputWrapper class="columns small-12 input-checkbox">
                <input
                  id="terms-checkbox"
                  name=terms-checkbox
                  type="checkbox"
                  v-model="acceptTermsCheckbox"
                />
                <label for="terms-checkbox">{{ $t('card.popup.accept.checkboxTerms', { value: activeCard.name || ''}) }}</label>
              </InputWrapper>
             <button class="button warning"  @click="togglePopupAcceptationCard()">{{$t("form.cancel")}}</button>
            <button class="button" :disabled="!acceptTermsCheckbox || !acceptPopupButton" @click="acceptCard()">{{$t("form.validate")}}</button>

            </template>
        </Popup>
        <Popup v-if="showPopupRefuseCard" hasClose @close="togglePopupRefuseCard()">
          <div v-html="$t('card.popup.refuse.bodyMessage', { cardName: activeCard.name || '',companyName: activeCard.program.name})" />
          <button class="button" @click="refuseCard()" :disabled="!refusePopupButton">{{ $t("card.actions.refuseConfirm") }}</button>
        </Popup>
        <!-- Cards Header -->
        <ViewHeader
          :title="$t('card.hello', { value: store.user ? store.user.firstname : '' })"
          :subtitle="cards.length > 1 ? $t('card.message_multicards') : $t('card.message_monocard')"
        />

        <div class="columns small-12 medium-10 large-8">
          <ViewDetails v-if="activeCard"
          :subtitle="activeCard.program ? $t('card.card',  {type:$t('card.type-'+activeCard.program.type),value : activeCard.program.cardName}) : ''"
          :content="$t('card.amount') + '&nbsp;<span>' + Intl.NumberFormat($i18n.locale, { style: 'currency', currency: activeCurrency, currencyDisplay: 'symbol'}).format(activeCard.amount) + '</span>'"
          />
          <!-- Display Cards -->
          <div class="cards-carousel" ref="carousel" :class="active ? 'active': ''" >
            <div class="cards-viewport" ref="cardsCarousel">
              <div
                class="card-placeholder"
                v-for="(card, index) in cards"
                :key="card.id"
                @click="slideTo(index, card)"
              >
                <CardItem :card="card" :class="[activeCard.id === card.id ? 'active' : '']" />
              </div>
            </div>
          </div>

          <div v-if="activeCard" class="text-center">
            <!--div class="active-card-amount">

              <div>{{ activeCard.program ? activeCard.program.name : '' }}</div>

              <div>
                {{ $t("card.amount") }}
                <span>{{ $n(activeCard.amount , 'currency') }}</span>
              </div>
            </div-->

            <!-- Use this card button -->
            <router-link
              :to="{ name: 'card-item', params: { id: activeCard.id } }"
              v-if="activeCard.id">
              <button class="button">{{ $t("card.use") }}</button>
            </router-link>
<!--            usecase plastic card -->
<!--            <div v-if="activeCard.statusAcceptationCard === 'WAITING'" class="flex-container flex-dir-column">-->
<!--              <div class="flex-child-auto"><button class="button" @click="togglePopupAcceptationCard()">-->
<!--                {{ $t("card.actions.accept") }}</button></div>-->
<!--              <div class="flex-child-auto"><button class="button warning" @click="togglePopupRefuseCard()">{{ $t("card.actions.refuse") }}</button></div>-->
<!--            </div>-->
<!--            <p v-if="activeCard.statusAcceptationCard === 'REFUSED'">-->
<!--              Vous avez refusé cette carte. S'il s'agit d'une erreur, veuillez contacter l'administrateur de la carte.-->
<!--            </p>-->

            <!-- Card message -->
            <!-- Reward -->
            <div class="row active-card-message" v-if="activeCard.reward">
              <div class="columns small-4 active-card-avatar">
                <img alt :src="activeCard.reward.avatar" />
              </div>
              <div class="columns active-card-content">{{ activeCard.reward.message }}</div>
            </div>
            <!-- Support -->
            <div class="row active-card-message" v-if="activeCard.support">
              <div class="columns small-4 active-card-avatar">
                <div>{{ activeCard.support.title }}</div>
                <img alt :src="activeCard.support.avatar" />
              </div>
              <div class="columns active-card-content">{{ activeCard.support.message }}</div>
            </div>
          </div>
        </div>
      </div>
    </GetterWrapper>
  </div>
</template>

<script src="./home.js"></script>
