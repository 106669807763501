import CardIcon from "@/components/card-icon/card-icon.vue";

export default {
  name: 'BtnCardAction',
  components: {CardIcon},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    infos: {
      type: Object,
      default: {}, // 'Faire un don' etc
      required: true
    },
    click: {
      type: Function,
      default: () => {
      },
      required: false
    }
  },
  data() {
    return {}
  },
  computed: {},
  methods: {}
}
