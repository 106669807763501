/* use https://github.com/bbonch/vue3-recaptcha2 */
import vueRecaptcha from 'vue3-recaptcha2';
export default {
  name: 'FormRecaptcha',
  components: {
    vueRecaptcha
  },
  props: {
    recaptchaKey: {
      type: String,
      default: '',
      required: true
    },
  },
  data () {
    return {}
  },
  emits: ['render', 'verify2'],
  computed: {},
  methods: {
    onExpired(){
      this.$refs.vueRecaptcha.reset();
    },
    onError(e){
      console.warn('VUE RECAPTCHA ERROR ',e)
    }
  }
}
