<template>
  <div class="activation row align-center">
    <ViewHeader title=""></ViewHeader>


    <div class="first-step columns small-12 medium-10 large-8" v-if="!emailSended">
      <FormWrapper
        :api="postFormPlasticCardActivation.bind(this, this.form)"
        :btnDisabled="(hasCaptcha && !verifiedCaptcha)"
        :cancelDisabled="true"
        :btnText="`${$t('activation.btnText')}`"
        :customErrors="errors"
        :validation="v$.form"
        @submitted="onSubmitted"
        @error="onError"
      >
        <fieldset>
          <div class="row align-center">
            <h2 class="activation">
              <span v-html="$t('activation.form.intro')"></span>
              <a href="#" v-on:click="onShowPopupHelp" class="icon"></a>
            </h2>
          </div>
          <div class="row align-center">
            <InputWrapper :validation-path="'form.email'" class="columns small-12 medium-7"
                          :label="`${$t('activation.form.email')}`">
              <input name="email" type="email" v-model.trim.lazy="v$.form.email.$model" required/>
            </InputWrapper>
            <FormRecaptcha
              class="columns small-12 medium-7"
              :recaptchaKey="platform.recaptchaKey"
              @render="onRenderCaptcha"
              @verify="onVerifyCaptcha"
            ></FormRecaptcha>
          </div>
        </fieldset>
      </FormWrapper>

      <Popup v-if="showPopupViewHelp" hasClose @close="onClosePopupHelp">
        <h3>{{ $t('activation.help.title') }} </h3>
        <div class="row align-center">
          <p>{{ $t('activation.help.form.intro') }}</p>
        </div>
        <FormWrapper
          :api="sendFormActivationHelp.bind(this, this.helpForm)"
          :cancelDisabled="true"
          :btnText="`${$t('activation.help.btnText')}`"
          :bypass-error="true"
          :validation="v$.helpForm"
          @submitted="onSubmittedHelp"
        >
          <fieldset>
            <div class="row align-center">
              <div class="columns small-6 medium-6">
                <InputWrapper :validation-path="'helpForm.firstname'"
                              :label="`${$t('activation.helpForm.firstname')}`">
                  <input name="email" type="email" v-model.trim.lazy="v$.helpForm.firstname.$model" required/>
                </InputWrapper>
              </div>
              <div class="columns small-6 medium-6">
                <InputWrapper :validation-path="'helpForm.lastname'"
                              :label="`${$t('activation.helpForm.lastname')}`">
                  <input name="email" type="email" v-model.trim.lazy="v$.helpForm.lastname.$model" required/>
                </InputWrapper>
              </div>
              <div class="columns small-12 medium-12">
                <InputWrapper :validation-path="'activation.helpForm.email'"
                              :label="`${$t('activation.helpForm.email')}`">
                  <input name="email" type="email" v-model.trim.lazy="v$.helpForm.email.$model" required/>
                </InputWrapper>
              </div>

              <div class="columns small-12 medium-12">
                <InputWrapper :validation-path="'activation.helpForm.message'"
                              :label="`${$t('activation.helpForm.message')}`">
                  <textarea name="message" type="message" v-model="v$.helpForm.message.$model" required/>
                </InputWrapper>
              </div>
              <input type="hidden" name="subject" v-model.trim.lazy="v$.helpForm.subject.$model">
            </div>
          </fieldset>
        </FormWrapper>
        <p class="text-center"
           v-html="$t('activation.help.footer.info')"></p>
      </Popup>
    </div>

    <div class="success align-center columns small-12 medium-10 large-8 " v-if="emailSended">
      <div class="row align-center">
        <div class="width-100 icon-check margin-top-2"/>
        <h2 class="activation width-100 margin-top-2">{{ $t('activation.success.message', {value: form.email}) }}</h2>
        <div class="letter margin-top-2"/>
        <p class="width-100 row align-center margin-top-2  margin-bottom-2" v-html="$t('activation.success.action', {value: form.email})"></p>
      </div>
      <!--        <image xlink:href="your.svg" src="yourfallback.png" width="90" height="90"/>-->
    </div>
  </div>
</template>

<script src="./activation.js">

import {defineComponent} from "vue";

export default defineComponent({
  components: {}
})
</script>
<style lang="scss" src="./activation.scss"></style>
