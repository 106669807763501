<template>
  <form
    :id="`form-wrapper-${id}`"
    class="form-wrapper row align-center"
    novalidate
    @submit.prevent="save"
  >
    <div class="columns small-12">
      <slot />
    </div>

    <div class="form-wrapper-toolbar columns small-12">
      <slot name="tool-bar" />
      <div v-if="!cancelDisabled" class="form-cancel-action margin-1" @click="$emit('cancel')">{{$t('form.cancel')}}</div>
      <slot name="tool-bar-submit" :state="btnState" :disabled="btnDisabled || saveLoading">
        <BtnMultiStates
          :id="`save-${id}-btn-submit`"
          type="submit"
          class="button form-submit-action"
          :class="btnClass"
          :disabled="btnDisabled  || saveLoading"
          :state="btnState"
          :text="btnText"
        />
      </slot>
    </div>

    <div class="columns small-12">
      <slot name="error">
        <ListErrors v-if="saveError" :errors="saveError" />
      </slot>
    </div>
  </form>
</template>

<script src="./form-wrapper.js"></script>
<style scoped lang="scss" src="./form-wrapper.scss"></style>
