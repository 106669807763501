<template>
  <div :class="'popup row align-center align-middle '+ (hasFooter ? 'has-footer' : '')">
    <div class="columns small-12 medium-10 large-8">
      <div class="row align-center">
        <div class="popup-wrapper small-12 medium-7">
          <div
            class="popup-content columns  text-center"
            :class="{'with-close' : hasClose}"
          >
            <div v-if="hasClose" class="popup-close-icon" @click="$emit('close')">
              <i class="sofont sofont-cross-close"></i>
            </div>
            <slot/>
          </div>
          <div v-if="hasFooter" class="popup-footer text-center">
            <slot name="footer"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./popup.js"></script>
<style lang="scss" src="./popup.scss"></style>
