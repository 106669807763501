import Vue from "vue"
import {createRouter, createWebHistory} from 'vue-router';
import Home from '@/views/home/home.vue';
import KitUi from '@/views/kit-ui/kit-ui.vue';
import Card from '@/views/card-detail/card-detail.vue';
import Statement from '@/views/card-detail/statement/statement.vue';
import Rewards from '@/views/card-detail/rewards/rewards.vue';
import Buy from "@/views/card-detail/buy/buy.vue";
import Store from "@/views/card-detail/store/store.vue";
import Balance from "@/views/card-detail/balance/balance.vue";
import Tcos from "@/views/tcos/tcos.vue";
import Iban from "@/views/card-detail/refund-iban/refund-iban.vue";
import Xpay from "@/views/card-detail/xpay/xpay.vue";
import Banners from "@/views/card-detail/banners/banners.vue";
import Login from "@/views/login/login.vue"
import Logout from "@/views/logout/logout.vue"
import Signup from "@/views/signup/signup.vue"
import Activation from "@/views/activation/activation.vue"
import ForgottenPassword from "@/views/forgotten-password/forgotten-password.vue";
import Message from "@/views/message/message.vue"
import Help from "@/views/help/help.vue";
import Contact from "@/views/contact/contact.vue"
import Account from "@/views/account/account.vue";
import Informations from "@/views/account/informations/informations.vue";
import Security from "@/views/account/security/security.vue"
import Preferences from "@/views/account/preferences/preferences.vue"
import Mentions from "@/views/mentions/mentions.vue"
import Politics from "@/views/politics/politics.vue"
import Cgu from "@/views/cgu/cgu.vue"
import External from '@/views/external/external.vue'
import Trust from '@/views/trust/trust.vue'
import Activate from "@/views/card-detail/activate/activate.vue";

const routes = [
  {
    path: "/",
    name: 'home',
    meta: {permittedRoles: [], icon: "sofont sofont-card", text: 'home'},
    props: true,
    component: Home
  },
  {
    path: "/kitui",
    name: 'kit-ui',
    meta: {},
    props: true,
    component: KitUi
  },
  {
    path: "/card/:id/:status?",
    name: 'card-item',
    meta: {permittedRoles: []},
    props: true,
    component: Card
  },
  {
    path: "/card/:id/statement",
    name: 'card-item-statement',
    meta: {permittedRoles: []},
    props: true,
    component: Statement
  },
  {
    path: "/card/:id/rewards",
    name: 'card-item-rewards',
    meta: {permittedRoles: []},
    props: true,
    component: Rewards
  },
  {
    path: "/card/:id/donate",
    name: 'card-item-donate',
    meta: {permittedRoles: []},
    props: true,
    component: Buy
  },
  {
    path: "/card/:id/store",
    name: 'card-item-store',
    meta: {permittedRoles: []},
    props: true,
    component: Store
  },
  {
    path: "/card/:id/balance",
    name: 'card-item-balance',
    meta: {permittedRoles: []},
    props: true,
    component: Balance
  },
  {
    path: "/card/:id/activate",
    name: 'card-item-activate',
    meta: {permittedRoles: []},
    props: true,
    component: Activate
  },
  {
    path: "/card/:id/tcos",
    name: 'card-item-tcos',
    meta: {permittedRoles: []},
    props: true,
    component: Tcos
  },
  {
    path: "/card/:id/refund/iban",
    name: 'card-item-refund-iban',
    meta: {permittedRoles: []},
    props: true,
    component: Iban
  },
  {
    path: "/card/:id/xpay",
    name: 'card-item-xpay',
    meta: {permittedRoles: []},
    props: true,
    component: Xpay
  },
  {
    path: "/card/:id/banners?type=:type",
    name: 'card-item-banners',
    meta: {permittedRoles: []},
    props: true,
    component: Banners
  },
  {
    path: "/login/:expired?",
    name: 'login',
    meta: {permittedRoles: []},
    component: Login
  },
  {
    path: "/logout",
    name: 'logout',
    meta: {permittedRoles: []},
    component: Logout
  },
  {
    path: "/signup/:code",
    name: 'signup',
    meta: {permittedRoles: []},
    component: Signup
  },
  {
    path: "/activation",
    name: 'activation',
    meta: {permittedRoles: []},
    component: Activation
  },
  {
    path: "/forgotten-password",
    name: 'forgotten-password',
    meta: {permittedRoles: []},
    component: ForgottenPassword
  },
  {
    path: "/messages",
    name: 'messages',
    meta: {permittedRoles: [], icon: "sofont sofont-message", text: 'message'},
    component: Message
  },
  {
    path: "/help",
    name: 'help',
    meta: {permittedRoles: [], icon: "sofont sofont-help", text: 'help'},
    component: Help
  },
  {
    path: "/contact",
    name: 'contact',
    meta: {permittedRoles: []},
    component: Contact
  },
  {
    path: "/account",
    name: 'account',
    meta: {permittedRoles: [], icon: "sofont sofont-account", text: 'account'},
    component: Account
  },
  {
    path: "/account/informations",
    name: 'account-informations',
    meta: {permittedRoles: []},
    component: Informations
  },
  {
    path: "/account/security",
    name: 'account-security',
    meta: {permittedRoles: []},
    component: Security
  },
  {
    path: "/account/preferences",
    name: 'account-preferences',
    meta: {permittedRoles: []},
    component: Preferences
  },
  {
    path: "/mentions",
    name: 'mentions',
    meta: {permittedRoles: []},
    component: Mentions
  },
  {
    path: "/politics",
    name: 'politics',
    meta: {permittedRoles: []},
    component: Politics
  },
  {
    path: "/cgu",
    name: 'cgu',
    meta: {permittedRoles: []},
    component: Cgu
  },
  {
    path: '/external/:urlencoded/',
    name: 'external',
    meta: {permittedRoles: []},
    props: true,
    component: External
  },
  {
    path: '/trust/:token',
    name: 'trust',
    meta: {permittedRoles: []},
    props: true,
    component: Trust
  },
  {
    path: '/*',
    meta: {permittedRoles: []},
    redirect: {name: 'home'}
  },
  /*{
   path: "/card/:id/online",
   name: 'card-item-online',
   meta: { permittedRoles: [] },
   props: true,
   component: () =>
     import("@/views/card-detail/buy/buy.vue")
 },*/
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return {left: 0, top: 0}
  },
})

// Check if route has route query params
function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

// Maintain query params in url
router.beforeEach((to, from, next) => {
  console.log('[ROUTER] before , from ', from.name, from.params, ' to ', to.name, to.params, to.query)
  if (!hasQueryParams(to) && hasQueryParams(from)) {

    const _route = {name: to.name, query: from.query}
    if (to.params) {
      _route.params = to.params
    }
    //console.log('[ROUTER] next route ', _route)
    next(_route)
  } else {
    // console.log('[ROUTER] next sans route ')
    next()
  }
})

router.onError(error => {

  if (/loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
    console.warn('ROUTER ERROR CHUNK, want to reload')
    window.location.reload()
  }

}); // onError
// Expose the router
export default router
