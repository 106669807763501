export default {
  name: 'ViewBlocs',
  components: {},
  props: {
    blocs: {
      type: [Array, String],
      default: [],
      required: true
    },
    section: {
      type: String,
      default: '',
      required: true
    },
  },
  data () {
    return {}
  },
  computed: {
    formattedBlocs () {
      return Array.isArray(this.blocs) ? this.blocs : []
    }
  },
  methods: {},
}
