import axios from 'axios'
import router from '@/router'
import store from "@/store"
import JwtDecode from '@/helpers/jwt-decoder.js'
import {clearStoreToken, getStorePlatform, getStoreToken, postFormKeycloakRefresh, setStoreToken} from '@/store/api'

console.log('NEW version', new Date())
const http = axios.create({})
//console.log(http)
// Flag for only once token renew at the same time
let isTokenRenewing = false

// on request..
http.interceptors.request.use(
  async (req) => {
    console.log('[HTTP INTER] request to', req.url, 'with timeout ', req.timeout)

    // Add timestamp to all API requests to avoid cache
    req.params = Object.assign({_: Date.now()}, req.params)

    //only for login request from trust page, do not use token stored
    let fromTrustPage = (document.URL.includes('trust') || router.currentRoute.value.params.trust)
    // console.log('[HTTP INTER] is from trust page ? ', fromTrustPage && req.url == process.env.VUE_APP_EXTERNAL_LOGIN)


    // Get token from localstorage (important for first connection : no wait for store available)
    let {token} = await getStoreToken()
    const platform = await getStorePlatform()

    if (!(fromTrustPage && req.url == process.env.VUE_APP_EXTERNAL_LOGIN)) {
      // console.log('[HTTP INTER] request : token exist ? ', token ? 'true' : 'false', req.url, process.env.VUE_APP_EXTERNAL_LOGIN)
      //  console.log('[HTTP INTER] request : token :' ,token, ' / !isTokenRenewing : ', !isTokenRenewing, ' /platform :', platform)

      // If token available, check expiration
      if (token && !isTokenRenewing && platform) {
        //console.log('[HTTP INTER] request : token available with vueJWT', JwtDecode, token, atob)
        // console.log('[HTTP INTER] request : token available with vueJWT decode', JwtDecode.decode(token))

        const tokenDecrypt = JwtDecode.decode(token)
        // console.log('[HTTP INTER] TOKEN DECRYPT date now', Date.now(), ' / token date ',(tokenDecrypt.exp * 1000))

        let isTokenExpired = tokenDecrypt.exp * 1000 < Date.now()

        //console.log('[HTTP INTER] TOKEN EXPIRED ? ', isTokenExpired, tokenDecrypt, !fromTrustPage)
        if ((!fromTrustPage) && tokenDecrypt && isTokenExpired) {
          console.log('[HTTP INTER] tokenDecrypt & token expired condition')
          // Debug

          // Set a renewing
          isTokenRenewing = true

          // Get new token from keycloak
          try {
            console.log('[HTTP INTER] request try : token available (post form keycloack refresh')
            const res = await postFormKeycloakRefresh()
            const data = res.data
            console.log('[HTTP INTER] keyclock toekn data ', data)
            if (data && data.access_token) {
              console.log('[HTTP INTER] request : token ok, store ')
              token = data.access_token
              await setStoreToken(data.access_token, data.refresh_token)
            } else {
              throw Error(`[HTTP INTER] Oups il semble manquer l'access_token suite à l'appel pour le renouveler`)
            }
          } catch (e) {
            console.log('[HTTP INTER] request : token available CATCH, push to expired after clear token')
            const tokenExpired = store.state.token;
            await clearStoreToken()
            if (!document.URL.includes('trust')) {
              const payloadJwt = parseJwt(tokenExpired)
              if (payloadJwt && payloadJwt.redirection_url) window.location.href = payloadJwt.redirection_url;
              else router.push({path: '/login/expired'})
            }
          }

          // Unblock flag
          isTokenRenewing = false
        }
      }
      //console.log('[HTTP INTER] TOKEN AND PLATFORM ? ', token & platform)
      // If token is still valid, add auth to headers
      if (token && platform) {
        //console.log('[HTTP INTER] request : token still valid add authorization to headers')
        req.headers.Authorization = `Bearer ${token}`
      }
    }


    // add here the possible platform id
    if (platform && platform.id) {
      //console.log('[HTTP INTER] request : add platform ')
      req.headers.platformId = platform.id
    }
    return req
  },
  (error) => {
    return Promise.reject(error)
  }
)

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

// on response..
http.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (!error.response || !error.response.status) {
      return Promise.reject(error)
    }
    console.log('[HTTP INTER] response async error ', error, error.response.status)
    // TODO : transform to variable
    const redirectWhitelist = ['/login', '/signup', '/forgotten-password', '/validation']
    //console.log('redirect white list', redirectWhitelist, router.currentRoute.value.path, router.currentRoute.value.path.includes('trust/'))
    // Manage status response
    switch (error.response.status) {
      case 401:
        if (router.currentRoute.value.path.includes('trust/')) {
          console.warn('HTTP Interceptor ⛔ 401, for trust page with request url ', error)
          break
        }
        if (!redirectWhitelist.includes(router.currentRoute.value.path) && !router.currentRoute.value.params.expired) {
          console.warn('HTTP Interceptor ⛔ 401, goto /login')
          const platform = await getStorePlatform()
          if (platform && platform.urlRedirect) window.location.href = platform.urlRedirect;
          else router.push({path: '/login'}).catch(() => {
          });
        }
        break
      case 500:
        store.commit('displayErrorBanner', true)
        console.warn('HTTP Interceptor ⛔ 500, display error /login')
        break
    }
    return Promise.reject(error)
  }
)

export default http
