<template>
  <div>
    <div class="card__wallester_content ">
      <img v-if="action" :src="bgUrl" class="card__bg darkMode"/>
      <img v-else :src="bgUrl" class="card__bg"/>
      <div class="card__infos">
        <div :class='"card__logo-container" +(action?" darkMode":"")'>
          <img class="logo" :src="programLogoUrl"/>
        </div>
        <div v-if="!action" class="card__number card-row">
        <span v-for="(n, index) in cardNumber" :key="'num-' + index">{{
            n
          }}</span>
        </div>

        <div v-if="type === 'back' && !action" class="card__other card-row">
          <div>
            <span>{{ $t("card.expire") }}</span>
            <span>{{ displayedCard.expiration }}</span>
          </div>
          <div>
            <span>{{ $t("card.cvv") }}</span>
            <span>{{ displayedCard.cvv }}</span>
          </div>
        </div>
        <div v-if="action === '3ds' && displayedCard.secret" class="card__other card-row">
          <div class="threeDs">
            <h3>{{ $t('card.validationCode') }}</h3>
            <div class="secret">{{ displayedCard.secret }}</div>
            <div class="action">
              <button class="card__copy card__secret-copy" @click="copyCardSecret">
                <i class="sofont sofont-copy"></i>
                {{ $t("card.copyCode") }}
              </button>
            </div>
          </div>
        </div>
        <div v-if="action === 'pin' && displayedCard.pin" class="card__other card-row">
          <div class="pin">
            <h3>{{ $t('card.pinCode') }}</h3>
            <div class="secret">{{ displayedCard.pin }}</div>
          </div>
        </div>
        <div v-else-if="action === 'blocked'" class="card__other card-row">
          <div class="blocked">
            <div class="card_locked sofont sofont-locked"/>
            <span class="align-center">{{ $t('card.blocked.message', {value: dateBlocked()}) }}</span>
          </div>
        </div>
        <span v-if="!action && cardName" :class="'card__name card__name-' + card.id">{{
            cardName
          }}</span>
        <img v-if="!action && card.graphics" class="card__type" :src="cardTypeLogoUrl"/>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  name: "CardContentWallester",
  props: ["card", "type", "displayedCard", "action"],
  computed: {
    bgUrl() {
      return this.card
        ? "data:" +
        this.card.graphics.typeMimeBackground +
        ";base64," +
        this.card.graphics.background
        : ""
    },
    programLogoUrl() {
      return (
        "data:" +
        this.card.graphics.typeMimeProgramLogo +
        ";base64," +
        this.card.graphics.programLogo
      )
    },
    cardTypeLogoUrl() {
      return (
        "data:" +
        this.card.graphics.typeMimeCardTypeLogo +
        ";base64," +
        this.card.graphics.cardTypeLogo
      )
    },
    cardName() {
      return (
        this.$store.state.user.firstname + " " + this.$store.state.user.lastname
      )
    },
    cardNumber() {
      const num = this.displayedCard.number
      return [
        num.substring(0, 4),
        num.substring(4, 8),
        num.substring(8, 12),
        num.substring(12, 16)
      ]
    },
  },
  methods: {
    moment,
    dateBlocked() {
      let dateb = Date.parse(this.card.dateBlocked)
      console.log("date:",dateb)
      return moment(dateb).format("DD-MM-YYYY")
    },
    showAlert(text) {
      if (text) {
        this.copyAlert = text
        const to = setTimeout(() => {
          this.copyAlert = false
          clearTimeout(to)
        }, this.copyAlertTimeout)
      } else {
        this.copyAlert = false
      }
    },
    copyCardSecret() {
      navigator.clipboard.writeText(this.displayedCard.secret).then(
        () => {
          console.log("card secret copié ", this.displayedCard.secret)
          this.showAlert(this.$t("card.copyAlert"))
        },
        () => {
          console.error("card secret failed ")
          this.showAlert(false)
          /* clipboard write failed */
        }
      )
    },
  }
}
</script>

<style lang="scss">
@import "~@/styles/theme";

.card__wallester_content {
  .card_locked {
    content: url($card-locked);
  }
  .card__infos {
    justify-content: space-between;
  }

  .darkMode {
    filter: brightness(46%);
  }

  .blocked {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: center !important;
    align-items: center !important;
    margin-left: 0 !important;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    .sofont-locked {
      width: 38px;
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(1000%)
    }

    span {
      color: #FFF;
      text-align: center;
      font-family: "Plus Jakarta Sans" !important;
      font-size: 10px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: normal !important;
    }
  }

  .threeDs {
    position: absolute;
    top: 50px;
    left: 60px;

    h3 {
      color: var(--White, #FFF);
      text-align: center;
      /* H3 */
      font-family: Urbanist;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .secret {
      display: flex;
      padding: 10px;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;
      border-radius: 8px;
      background: #FFF;

      color: #000;

      text-align: center;
      font-family: "Plus Jakarta Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 180%; /* 32.4px */
    }

    .action button {
      margin-top: 15px;
      display: flex;
      align-items: flex-start;
      gap: 7px;
      color: var(--White, #FFF);
      text-align: center;
      /* Link */
      font-family: "Plus Jakarta Sans";
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .pin {
    position: absolute;
    top: 50px;
    left: 90px;

    h3 {
      color: var(--White, #FFF);
      text-align: center;
      /* H3 */
      font-family: Urbanist;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .secret {
      display: flex;
      padding: 10px;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;
      border-radius: 8px;
      background: #FFF;

      color: #000;

      text-align: center;
      font-family: "Plus Jakarta Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 180%; /* 32.4px */
    }

    .action button {
      margin-top: 15px;
      display: flex;
      align-items: flex-start;
      gap: 7px;
      color: var(--White, #FFF);
      text-align: center;
      /* Link */
      font-family: "Plus Jakarta Sans";
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}
</style>
