<template>
  <!-- Request cards data -->
  <div class="activate-input">
    <GetterWrapper id="balance" :api-load="apiLoad" @data="onData">
      <div class="balance row align-center">
        <ViewHeader
          :title="$t('card.hello', { value: store.user ? store.user.firstname : '' })"
        />
        <div class="columns small-12 medium-10 large-8">
          <ViewDetails
            :subtitle="card.program ? $t('card.card',  {type:$t('card.type-'+card.program.type),value : card.program.cardName}): ''"
            :content="$t('card.amount') + '&nbsp;<span>' + $n(card.amount, 'currency') + '</span>'"
          />

          <FormWrapper
            :api="null"
            :btn-text="`${$t('form.validate')}`"
            :btnDisabled="v$.form.$invalid || loading"
            :validation="v$.form"
            @cancel="onCancel"
            @submitted="onSubmitted"
            @error="onError"
          >
            <fieldset>
              <div class="row align-center">
                <h3 v-if="!isPlastic()">{{ $t('card.popup.accept.title', {value: card.name || ''}) }}</h3>
                <!--                <div class="title-icon">-->
                <h3 v-if="isPlastic()" class="title-icon">{{
                    $t('card.activate.plasticCard.title')
                  }}
                  <a href="#" v-on:click="onShowPopupViewCvv()" v-if="isPlastic()" class="icon"></a>
                  <Popup v-if="showPopupViewCvv" hasClose @close="onClosePopupViewCvv()">
                    <h3>{{ $t('card.activate.plasticCard.cvv.title') }} </h3>
                    <p class="text-center cvv"
                       v-html="$t('card.activate.plasticCard.cvv.info1')"></p>
                    <div class="card_back_cvv"></div>
                    <div id="notReceivedLink">
                      <router-link to="#" v-on:click="onShowPopupCardNotReceived()" v-if="isPlastic()">
                        {{ $t('card.activate.plasticCard.notReceived.link') }}
                      </router-link>
                    </div>
                  </Popup>
                </h3>
                <!--                </div>-->
                <div class="width-100 row align-center padding-bottom-1 padding-top-1">
                  <router-link to="#" v-on:click="onShowPopupCardNotReceived()" v-if="isPlastic()">
                    {{ $t('card.activate.plasticCard.notReceived.link') }}
                  </router-link>
                  <Popup v-if="showPopupCardNotReceived" hasClose @close="onClosePopupCardNotReceived()">
                    <h3>{{ $t('card.activate.plasticCard.notReceived.title') }} </h3>
                    <p class="text-center"
                       v-html="$t('card.activate.plasticCard.notReceived.info1',{value: '<a href=\'mailto:'+platform.emailSupport+'\'>'+platform.emailSupport+'</a>'})"></p>
                    <p class="text-center" v-html="$t('card.activate.plasticCard.notReceived.info2')"></p>
                  </Popup>
                </div>
                <input
                  type="text"
                  class="code-input"
                  v-if="isPlastic()"
                  :class="invalidNums[0]||error?'is-invalid':''"
                  v-model.lazy="v$.form.cvv_0.$model"
                  ref="cvv_0"
                  maxlength="1"
                  inputmode="numeric"
                  pattern="[0-9]{1}"
                  @input="updateCvv($event, 0)"
                  placeholder=''
                />
                <input
                  type="text"
                  v-if="isPlastic()"
                  class="code-input"
                  :class="invalidNums[1]||error?'is-invalid':''"
                  v-model.lazy="v$.form.cvv_1.$model"
                  inputmode="numeric"
                  pattern="[0-9]{1}"
                  ref="cvv_1"
                  maxlength="1"
                  @input="updateCvv($event, 1)"
                />
                <input
                  type="text"
                  v-if="isPlastic()"
                  class="code-input "
                  :class="invalidNums[2]||error?'is-invalid':''"
                  v-model.lazy="v$.form.cvv_2.$model"
                  inputmode="numeric"
                  pattern="[0-9]{1}"
                  ref="cvv_2"
                  maxlength="1"
                  @input="updateCvv($event, 2)"
                  placeholder=''
                />
              </div>
              <div v-if="error" class="row align-center margin-top-1 invalid-feedback">
                {{ $t('card.activate.error.cvv') }}
              </div>
              <div v-if="loading" class="margin-top-1">
                <Spinner :alt="$t('form.loading')"/>
              </div>
              <InputWrapper class="columns small-12 input-checkbox">
                <input
                  id="terms-checkbox"
                  name=terms
                  type="checkbox"
                  v-model="v$.form.terms.$model"
                />

                <label for="terms-checkbox">{{
                    $t('card.popup.accept.checkboxTerms', {value: card.name || ''})
                  }}</label>
              </InputWrapper>
            </fieldset>
          </FormWrapper>
          <div class="align-center" v-if="tos.length>0">
            <h3>{{ $t('tcos.title') }}</h3>
            <ViewBlocs :blocs="tos" section="tos"/>
          </div>
        </div>
      </div>
    </GetterWrapper>
  </div>
</template>
<script src="./activate.js"></script>
<style lang="scss" src="./activate.scss"></style>
