<template>
  <vue-recaptcha
    class="form-recaptcha"
    :sitekey="recaptchaKey"
    @render="$emit('render')"
    @expire="onExpired"
    @error="onError"
    @verify="$emit('verify2')"
    ref="vueRecaptcha"
  ></vue-recaptcha>
</template>

<script src="./form-recaptcha.js"></script>
<style scoped lang="scss" src="./form-recaptcha.scss"></style>
