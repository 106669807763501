import ViewHeader from "@/components/view-header/view-header.vue";
import FormWrapper from "@/components/form-wrapper/form-wrapper.vue";
import InputWrapper from "@/components/input-wrapper/input-wrapper.vue";
import {useVuelidate} from "@vuelidate/core";
import {email, required} from "@vuelidate/validators";
import {postFormPlasticCardActivation, sendFormActivationHelp} from "@/store/api";
import FormRecaptcha from "@/components/form-recaptcha/form-recaptcha.vue";
import Popup from "@/components/popup/popup.vue";
import Contact from "@/views/contact/contact.vue";

export default {
  name: 'Validation',
  components: {
    Contact,
    Popup,
    FormRecaptcha,
    InputWrapper,
    FormWrapper,
    ViewHeader,
  },
  props: {},
  data() {
    return {
      v$: useVuelidate(),
      form: {
        email: '',
      },
      helpForm: {
        email: '',
        firstname: '',
        lastname: '',
        message: this.$t("validation.helpForm.defaultMessage"),
        subject: this.$t("validation.helpForm.subject")
      },
      postFormPlasticCardActivation,
      sendFormActivationHelp,
      isModification: false,
      loadLoading: true,
      emailSended: false,
      platform: this.$store.state.platform,
      hasCaptcha: true,
      showPopupViewHelp: false,
      verifiedCaptcha: false
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
    },
    helpForm: {
      email: {
        required,
        email
      },
      firstname: {
        required,
      },
      lastname: {
        required,
      },
      message: {
        required,
      },
      subject: {
        required,
      },
    }
  },
  computed: {
    errors() {
      return [{
        response: {
          status: 500,
          data: {
            message: this.$t('errors.loginBlock'),
          }
        }
      }
      ]
    }
  },
  created: function () {
  },
  methods: {
    onRenderCaptcha(id) {
      if (id) this.hasCaptcha = true
    },
    onVerifyCaptcha(response) {
      if (response)
        this.verifiedCaptcha = true
    },
    async onSubmitted(data) {
      // console.log('submit data ', data)
      this.emailSended = true;

    },
    async onSubmittedHelp() {
      this.onClosePopupHelp()
    },
    onShowPopupHelp() {
      this.showPopupViewHelp = true
    },
    onClosePopupHelp() {
      this.showPopupViewHelp = false
    },
    onError() {
    },
  }
}
