
export default {
  name: 'CardIcon',
  components: {},
  props: {
    background: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: "",
      required: true
    },
  },
  data () {
    return {}
  },
  computed: {},
  methods: {
  }
}
