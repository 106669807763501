import {LS} from "@/const"
import BtnUrlAction from "@/components/btn-url-action/btn-url-action.vue"
import CardItem from "@/components/card-item/card-item.vue"
import CardItemWallester from "@/components/card-item-wallester/card-item-wallester.vue"
import CardRapydShow from "@/components/card-rapyd-show/card-rapyd-show.vue"
import GetterWrapper from "@/components/getter-wrapper/getter-wrapper.vue"
import ViewHeader from "@/components/view-header/view-header.vue"
import ViewDetails from "@/components/view-details/view-details.vue"
import SimulatePop from '@/components/simulate-pay-popup/simulate-pay-popup.vue';
import RoundIcon from "@/components/round-icon/round-icon.vue"
import Popup from '@/components/popup/popup.vue'
import {formatSeconds} from "@/helpers/date.js"
import {changeNumberFormats} from "@/helpers/i18n"
import {getCard, getCardGraphics, postPaymentIntent, putBlockCard, putUnblockCard} from "@/store/api"
import store from "@/store"
import {EventBus} from '@/event-bus.js'
import router from "@/router";
import BtnCardAction from "@/components/btn-card-action/btn-card-action.vue";
import moment from "moment-timezone";

export default {
  name: "CardDetail",
  components: {
    BtnCardAction,
    BtnUrlAction,
    CardItem,
    CardItemWallester,
    GetterWrapper,
    ViewHeader,
    ViewDetails,
    CardRapydShow,
    Popup,
    SimulatePop,
    RoundIcon
  },
  props: {},
  data() {
    return {
      apiLoad: [
        {
          api: getCard,
          data: {id: this.$route.params.id}
        },
        {
          api: getCardGraphics,
          data: {id: this.$route.params.id}
        }
      ],
      card: null,
      cardShown: false,
      cardShown3ds: false,
      cardShownPin: false,
      cardBlocked: false,
      blockQuestion: false,
      actionOnCard: null,
      countDown: LS.CARD_SHOW_TIMOUT_SECS,
      store: this.$store.state,
      activateStatus: this.$route.params.status,
      timer: "",
      loading: false,
      showMaintenancePop: false,
      showSimulatePop: false,
      plasticCard: false,
      platform: {}
    }
  },
  computed: {
    formatedCountdown() {
      return formatSeconds(this.countDown)
    },
    cardHasXpay() {
      console.log('card has xpay ? ', this.card.program.xp)
      return this.card.program.xp
      //return true
    },
    isMaintenanceMode() {
      console.log('card is maintenance mode ? ', this.card.program.underMaintenance)
      return this.card.program.underMaintenance
    },
    canSimulatePayment() {
      console.log('can simulate payment ', process.env.VUE_APP_SIMULATE_PAYMENT)
      return process.env.VUE_APP_SIMULATE_PAYMENT;
    }
  },

  // beforeRouteLeave(to, from, next){
  //   console.log('before route leave ', to.name, !to.params.FLType,  this.card.program.type)
  //   if(to.name == 'help' && !to.params.FLType && this.card.program.type.startsWith('FL')){
  //     next({
  //       name: to.name,
  //       params: {
  //         FLType: this.card.program.type.startsWith('FL')
  //       },
  //       replace: true
  //     })
  //   } else {
  //     next();
  //   }
  // },

  methods: {
    onData(data) {
      this.card = data[0]
      this.plasticCard = this.card.plastic
      this.cardBlocked = this.card.blocked
      if (this.cardBlocked) this.actionOnCard = 'blocked'
      store.commit("setCurrentCard", this.card)
      store.commit("setProgId", this.card.program.id)
      EventBus.emit("cardSelected", this.card)
      const numberFormat = this.$i18n.numberFormats[this.$i18n.locale]
      numberFormat.currency.currency = this.card.program.currency
      changeNumberFormats(this.$i18n.locale, numberFormat)
      this.card.url = data[2]
      if (this.card.program.monetician === "WALLESTER") {
        this.card.graphics = data[1]
      }
      this.platform = this.$store.state.platform

      // console.debug(this.$i18n.numberFormats)
      console.debug("card loaded,", data)
    },
    closeSucessActivatedMessage() {
      this.card.statusAcceptationCard = 'ACCEPTED'
      this.activateStatus = null
    },
    countDownTimer() {
      this.timer = setTimeout(() => {
        this.countDown -= 1
        if (this.countDown > 0) {
          this.countDownTimer()
        } else if (this.countDown <= 0) {
          this.hideCard()
        }
      }, 1000)
    },
    hideCard() {
      clearTimeout(this.timer)
      this.cardShown = false
      this.countDown = LS.CARD_SHOW_TIMOUT_SECS
    },
    async showCardDetails() {
      console.log(this.card.program)
      if (this.isMaintenanceMode) {
        this.showMaintenancePop = true
        return;
      }
      this.cardShown = true
      // Désactivé temporairement
      if (this.card.program.monetician !== "IBAN") {
        this.countDownTimer()
      }
    },

    async closeCardDetails() {
      console.log(this.card.program)
      if (this.isMaintenanceMode) {
        this.showMaintenancePop = true
        return;
      }
      this.cardShown = false
    },
    async show3ds() {
      if (this.isMaintenanceMode) {
        this.showMaintenancePop = true
        return;
      }
      this.actionOnCard = '3ds'
      this.cardShown3ds = true
      this.cardShown = false
    },
    async showPin() {
      if (this.isMaintenanceMode) {
        this.showMaintenancePop = true
        return;
      }
      this.actionOnCard = 'pin'
      this.cardShownPin = true
      this.cardShown = false
    },
    async blockAction() {
      if (this.isMaintenanceMode) {
        this.showMaintenancePop = true
        return;
      }
      this.blockQuestion = true
      this.cardShown = false
    },
    async cancelBlockAction() {
      if (this.isMaintenanceMode) {
        this.showMaintenancePop = true
        return;
      }
      this.blockQuestion = false
    },
    async block() {
      if (this.isMaintenanceMode) {
        this.showMaintenancePop = true
        return;
      }
      this.loading = true
      try {
        await putBlockCard(this.card.id)
        this.card.blocked = true
        this.card.dateBlocked = moment().toISOString()
        this.cardBlocked = true
        this.actionOnCard = 'blocked'
        this.blockQuestion = false
      } catch (error) {
        console.error(error)
        this.$emit('error')
      } finally {
        this.loading = false
      }
    },

    async unblock() {
      if (this.isMaintenanceMode) {
        this.showMaintenancePop = true
        return;
      }
      this.loading = true
      try {
        await putUnblockCard(this.card.id)
        this.card.blocked = false
        this.card.dateBlocked = null
        this.cardBlocked = false
        this.actionOnCard = null
      } catch (error) {
        console.error(error)
        this.$emit('error')
      } finally {
        this.loading = false
      }
    },
    close3dsdMessage() {
      this.actionOnCard = null
      this.cardShown3ds = false
    },

    closePindMessage() {
      this.actionOnCard = null
      this.cardShownPin = false
    },

    async goToActivateCard() {
      router.push({
        name: 'card-item-activate',
        params: {id: this.card.id}
      })
    }
  },
  mounted() {
  }
}
