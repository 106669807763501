import CardItem from "@/components/card-item/card-item.vue"
import GetterWrapper from "@/components/getter-wrapper/getter-wrapper.vue"
import InputWrapper from "@/components/input-wrapper/input-wrapper.vue"
import ViewHeader from "@/components/view-header/view-header.vue"
import ViewDetails from "@/components/view-details/view-details.vue"
import Popup from '@/components/popup/popup.vue'
import ViewBlocs from '@/components/view-blocs/view-blocs.vue'
import { getCards, changeStatusCard } from '@/store/api'
import $ from "jquery"
import slick from 'slick-carousel'
import store from "@/store"
import { nextTick } from 'vue'
export default {
  name: 'Home',
  components: { CardItem, GetterWrapper, ViewHeader, ViewDetails, Popup, InputWrapper, ViewBlocs },
  props: {},
  computed: {

  },
  data () {
    return {
      active: false,
      activeCard: {},
      activeCurrency: this.$i18n.numberFormats[this.$i18n.locale].currency.currency,
      apiLoad: [
        {
          api: getCards,
        }
      ],
      tos: [],
      cards: [],
      store: this.$store.state,
      showPopupAcceptationCard: false,
      showPopupRefuseCard: false,
      acceptTermsCheckbox: false,
      acceptPopupButton: true,
      refusePopupButton: true,
      initialSwipeX: null,
      initialSwipeY: null,
      carousel: null,
      carouselOptions: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: true,
        centerPadding: window.innerWidth < 768 ? '20px' : '50px',
        infinite: false,
        rows: 0
      }
    }
  },
  created: function () { /* possible init API call */ },
  mounted: function () { /* DOM accessible via this.$el */
    //console.log('window.client', window.innerWidth)
  },
  beforeUnmount: function () { /* clean all non-Vue listeners/objects */ },
  methods: {
    onData ([data]) {
      this.cards = data || []

      // eslint-disable-next-line no-constant-condition
      if (this.cards.length === 1){//} && this.cards[0].statusAcceptationCard === 'ACCEPTED') {
        this.$router.push({ name: 'card-item', params: { id: this.cards[0].id } })
        return
      }

      this.$store.commit('setCards', this.cards)
      // console.log('this cards ', this.cards)
      store.commit('setProgId', null)
      // Wait next tick to be sure than rendering is done
      nextTick(() => {

        this.initCarousel()
        this.setActiveCard(0)
        window.addEventListener('resize', this.refreshCarousel)
      })
    },
    initCarousel () {
      this.carousel = $(this.$refs.cardsCarousel).slick(this.carouselOptions)
      this.carousel.on('beforeChange', (evt, slick, currentSlide, nextSlide) => {
        // console.log('carousel change', nextSlide)
        this.setActiveCard(nextSlide)
      })

    },
    slideTo (index) {
      this.carousel.slick('slickGoTo', index)
    },

    setActiveCard (index) {
      const card = this.cards[index]
      this.active = true
      // this.activeCard = card
      this.activeCard = card
      this.activeCurrency = card.program.currency
      this.tos = JSON.parse(card.program.tos)[this.$i18n?.locale||'FR']

      // this.refreshCarousel()
    },
    refreshCarousel () {
      this.carousel.slick("getSlick").refresh()
      this.carousel.slick("slickSetOption", 'centerPadding', window.innerWidth < 768 ? '20px' : '50px',)
      /*    if (this.activeCard) {
        // Focus X axis on that card
        const _key = this.cards.findIndex(_card => this.activeCard.id === _card.id)
        const _carouselWidth = this.$el.querySelector(".cards-carousel").offsetWidth
        const _carouselHeight = this.$el.querySelector(".cards-carousel").offsetHeight
        const _cardWidth = this.$el.querySelector(".cards-carousel .card-item").offsetWidth

        // Calculate the start point for the first card
        const __height = _carouselHeight
        const __width = _carouselWidth * this.cards.length
        const _center = (_carouselWidth / 2) - (_cardWidth / 2)
        const _translationX = _center - ((_key) * _cardWidth)

        // Move axis
        this.$el.querySelector(".cards-carousel .cards-viewport").style.height = `${__height}px`
        this.$el.querySelector(".cards-carousel .cards-viewport").style.width = `${__width}px`
        this.$el.querySelector(".cards-carousel .cards-viewport").style.transform = `translate3d(${_translationX}px, 0, 0)`
      } */
    },
    togglePopupAcceptationCard () {
      this.showPopupAcceptationCard = !this.showPopupAcceptationCard
    },
    togglePopupRefuseCard () {
      this.showPopupRefuseCard = !this.showPopupRefuseCard
    },
    acceptCard () {
      this.acceptPopupButton = false
      changeStatusCard({
        id: this.activeCard.id,
        status: 'ACCEPTED'
      }).then((res) => {
        this.activeCard = res.data
        this.togglePopupAcceptationCard()
        this.acceptPopupButton = true
        this.acceptTermsCheckbox = false
        this.$router.push({ name: 'card-item', params: { id: this.activeCard.id } })
      })
    },
    refuseCard () {
      this.refusePopupButton = false
      changeStatusCard({
        id: this.activeCard.id,
        status: 'REFUSED'
      }).then(() => {
        this.cards.forEach((card) => {
          if (card.id === this.activeCard.id) {
            card.statusAcceptationCard = 'REFUSED'
          }
        })
        this.activeCard.statusAcceptationCard = 'REFUSED'
        this.togglePopupRefuseCard()
        this.refusePopupButton = true
      })
    }
  }
}
